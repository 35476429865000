import { createContext, useState, useEffect, ReactNode, useMemo, useRef } from "react";
// firebase
import { firestore } from "@src/firebase";
import { collection, getCountFromServer, getDocs, limit, onSnapshot, orderBy, Query, query, QueryConstraint, QueryDocumentSnapshot, startAfter, where } from "firebase/firestore";
// context
import { useStorageContext } from "@provider/StorageProvider";
// types
import { TeamsGameOption, TeamsRegionOption, TeamsFilterOption, TeamsSortingOption } from '@components/teams/types';
import { algoliaTeamConverter, DBTeam, teamConverter } from "@src/firestore/teams";
// constants
import { TeamResultsPerPage } from '@components/teams/utils';
// utils
import { debounce } from "@utils/Debounce";
// libraries
import algoliasearch from "algoliasearch";

// local types
type LastTeam = QueryDocumentSnapshot<DBTeam> | null;

interface TeamsCount {
  apexLegends: number,
  fortnite: number,
  valorant: number,
  rocketLeague: number,
}

const defaultTeamsCount = {
  apexLegends: 0,
  fortnite: 0,
  valorant: 0,
  rocketLeague: 0
};

interface ITeamsContext {
  teams: DBTeam[] // all tournaments
  featuredTeams: DBTeam[],
  totalTeamResultsCount: number,
  teamsCountByGame: TeamsCount,
  loadMoreTeams: (amountNeeded: number) => Promise<boolean>,
  loadingMore: boolean,
  searchQuery: string,
  searchQueued: boolean,
  setSearchQueued: (queued: boolean) => void,
  setSearchQuery: (query: string) => void,
  gameOption: TeamsGameOption,
  setGameOption: (option: TeamsGameOption) => void,
  regionOption: TeamsRegionOption,
  setRegionOption: (option: TeamsRegionOption) => void,
  sortingOption: TeamsSortingOption,
  setSortingOption: (option: TeamsSortingOption) => void,
  filterOptions: TeamsFilterOption[],
  setFilterOptions: (options: TeamsFilterOption[]) => void,
  initiallyLoaded: boolean
}

const defaultTeamsContext = {
  teams: [],
  featuredTeams: [],
  totalTeamResultsCount: 0,
  teamsCountByGame: defaultTeamsCount,
  loadMoreTeams: async () => false,
  loadingMore: false,
  searchQuery: '',
  searchQueued: false,
  setSearchQueued: (queued: boolean) => queued,
  setSearchQuery: (query: string) => query,
  gameOption: TeamsGameOption.Apex,
  setGameOption: (option: TeamsGameOption) => option,
  regionOption: TeamsRegionOption.ALL,
  setRegionOption: (option: TeamsRegionOption) => option,
  sortingOption: TeamsSortingOption.totalWinningsDesc,
  setSortingOption: (option: TeamsSortingOption) => option,
  filterOptions: [],
  setFilterOptions: (options: TeamsFilterOption[]) => options,
  initiallyLoaded: false
};

export const TeamsContext = createContext<ITeamsContext>(defaultTeamsContext);

const sortingConstraintConstructors = {
  totalWinningsDesc: (lastTeam: LastTeam) => lastTeam ? [orderBy('totalWinnings', 'desc'), orderBy('id'), startAfter(lastTeam)] : [orderBy('totalWinnings', 'desc'), orderBy('id')],
  nameDesc: (lastTeam: LastTeam) => lastTeam ? [orderBy('teamName'), orderBy('id'), startAfter(lastTeam)] : [orderBy('teamName'), orderBy('id')],
  playerCountDesc: (lastTeam: LastTeam) => lastTeam ? [orderBy('playerCount', 'desc'), orderBy('id'), startAfter(lastTeam)] : [orderBy('playerCount', 'desc'), orderBy('id')],
  trophiesWonDesc: (lastTeam: LastTeam) => lastTeam ? [orderBy('trophyCount', 'desc'), orderBy('id'), startAfter(lastTeam)] : [orderBy('trophyCount', 'desc'), orderBy('id')],
  tournamentsPlayedDesc: (lastTeam: LastTeam) => lastTeam ? [orderBy('tournamentsPlayed', 'desc'), orderBy('id'), startAfter(lastTeam)] : [orderBy('tournamentsPlayed', 'desc'), orderBy('id')],
  apexWinNumDesc: (lastTeam: LastTeam) => lastTeam ? [orderBy('apexMatchWins', 'desc'), orderBy('id'), startAfter(lastTeam)] : [orderBy('apexMatchWins', 'desc'), orderBy('id')],
  apexWinRateDesc: (lastTeam: LastTeam) => lastTeam ? [orderBy('apexMatchWinrate', 'desc'), orderBy('id'), startAfter(lastTeam)] : [orderBy('apexMatchWinrate', 'desc'), orderBy('id')]
};

const getQueryConstraintsForSortingOption = (option: TeamsSortingOption, lastTeam: LastTeam): QueryConstraint[] => {
  switch (option) {
    case TeamsSortingOption.totalWinningsDesc:
      return sortingConstraintConstructors.totalWinningsDesc(lastTeam);
    case TeamsSortingOption.nameDesc:
      return sortingConstraintConstructors.nameDesc(lastTeam);
    case TeamsSortingOption.playerCountDesc:
      return sortingConstraintConstructors.playerCountDesc(lastTeam);
    case TeamsSortingOption.trophiesWonDesc:
      return sortingConstraintConstructors.trophiesWonDesc(lastTeam);
    case TeamsSortingOption.tournamentsPlayedDesc:
      return sortingConstraintConstructors.tournamentsPlayedDesc(lastTeam);
    case TeamsSortingOption.apexWinNumDesc:
      return sortingConstraintConstructors.apexWinNumDesc(lastTeam);
    case TeamsSortingOption.apexWinRateDesc:
      return sortingConstraintConstructors.apexWinRateDesc(lastTeam);
    default:
      return sortingConstraintConstructors.totalWinningsDesc(lastTeam);
  }
};

const getQueryConstraintsForRegionOption = (option: TeamsRegionOption): QueryConstraint[] => {
  let region: string = '';
  switch (option) {
    case TeamsRegionOption.ALL:
      region = '';
      break;
    case TeamsRegionOption.EMEA:
      region = 'EMEA';
      break;
    case TeamsRegionOption.NA:
      region = 'NA';
      break;
    case TeamsRegionOption.LATAM:
      region = 'LATAM';
      break;
    case TeamsRegionOption.APAC:
      region = 'APAC';
      break;
    default:
      region = '';
  }

  return region !== '' ? [where('region', '==', region)] : [];
};

const getQueryConstraintsForFilterOptions = (options: TeamsFilterOption[]): QueryConstraint[] => {
  const getConstraintForFilterOption = (option: TeamsFilterOption): QueryConstraint => {
    switch (option) {
      case TeamsFilterOption.lookingForPlayers:
        return where('lookingForPlayers', '==', true);
      case TeamsFilterOption.threeMembers:
        return where('playerCount', '>', 3);
      case TeamsFilterOption.threeTournaments:
        return where('tournamentsPlayed', '>', 3);
    }
  };

  const constraints: QueryConstraint[] = [];
  for (const option of options) {
    constraints.push(getConstraintForFilterOption(option));
  }

  return constraints;
};

interface ITeamsProvider {
  announceTeamsLoaded: (loaded: boolean) => void,
  children: ReactNode
}

const TeamsProvider: React.FC<ITeamsProvider> = ({ children, announceTeamsLoaded }) => {
  const { storage, updateStorage, storageLoaded } = useStorageContext();
  const [filterOptionsLoaded, setFilterOptionsLoaded] = useState<boolean>(false);

  const [teams, setTeams] = useState<DBTeam[]>([]);
  const [initiallyLoaded, setInitiallyLoaded] = useState<boolean>(false);
  const [lastDBTeam, setLastDBTeam] = useState<LastTeam>(null);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);

  const [featuredTeams, setFeaturedTeams] = useState<DBTeam[]>([]);
  const [totalTeamResultsCount, setTotalTeamResultsCount] = useState<number>(0);

  const [teamsCountByGame, setTeamsCountByGame] = useState<TeamsCount>(defaultTeamsCount);

  const searchQueryRef = useRef<string>('');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchQueued, setSearchQueued] = useState<boolean>(false);

  const [gameOption, setGameOption] = useState<TeamsGameOption>(TeamsGameOption.Apex);
  const [regionOption, setRegionOption] = useState<TeamsRegionOption>(TeamsRegionOption.ALL);
  const [sortingOption, setSortingOption] = useState<TeamsSortingOption>(TeamsSortingOption.totalWinningsDesc);
  const [filterOptions, setFilterOptions] = useState<TeamsFilterOption[]>([]);

  const getFeaturedTeams = async () => {
    const teamsCollection = collection(firestore, 'teams').withConverter(teamConverter);
    const q = query(teamsCollection, orderBy('createdAt', 'desc'), where('dissolved', '==', false), limit(3));

    onSnapshot(q, async (snapshots) => {
      const localFeaturedTeams = snapshots.docs.map((doc) => doc.data());
      setFeaturedTeams(localFeaturedTeams);
    })
  }

  const loadFiltersFromLocalStorage = () => {
    const { teamsRegionOption, teamsSortingOption, teamsFilterOptions } = storage;

    if (teamsRegionOption !== undefined) {
      setRegionOption(teamsRegionOption);
    }

    if (teamsSortingOption !== undefined) {
      setSortingOption(teamsSortingOption);
    }

    if (teamsFilterOptions !== undefined) {
      setFilterOptions(teamsFilterOptions);
    }

    setFilterOptionsLoaded(true);
  };

  const saveFiltersToLocalStorage = () => {
    updateStorage({
      teamsRegionOption: regionOption,
      teamsSortingOption: sortingOption,
      teamsFilterOptions: filterOptions
    });
  };

  useEffect(() => {
    if (storageLoaded && !filterOptionsLoaded) {
      loadFiltersFromLocalStorage();
    }
  }, [storageLoaded, filterOptionsLoaded]);

  const getTeams = async (reset: boolean, amountNeeded: number) => {
    const teamsCollection = (collection(firestore, 'teams').withConverter(teamConverter));

    const queryRegionConstraints = getQueryConstraintsForRegionOption(regionOption);
    const querySortingConstraints = getQueryConstraintsForSortingOption(sortingOption, reset ? null : lastDBTeam);
    const queryFilterConstraints = getQueryConstraintsForFilterOptions(filterOptions);

    const q: Query<DBTeam> = query(teamsCollection,
      ...querySortingConstraints,
      ...queryFilterConstraints,
      ...queryRegionConstraints,
      where('dissolved', '==', false),
      limit(amountNeeded));

    const snapshots = await getDocs(q);
    const localTeams = snapshots.docs.map((doc) => doc.data());

    const lastTeam = snapshots.docs[snapshots.docs.length - 1];
    setLastDBTeam(lastTeam);

    if (reset) {
      setTeams(localTeams);
    } else {
      setTeams(prevTeams => [...prevTeams, ...localTeams]);
    }

    announceTeamsLoaded(true);
    setInitiallyLoaded(true);
    setLoadingMore(false);

    return localTeams.length > 0;
  };

  const getTeamsCountByGame = async () => {
    const teamsCollection = collection(firestore, 'teams');
    const apexCountQuery = query(teamsCollection, where('mainGame', '==', 'Apex Legends'), where('dissolved', '==', false));
    const apexCount = (await getCountFromServer(apexCountQuery)).data().count;

    const localTeamsCountByGame = {
      apexLegends: apexCount,
      fortnite: 0,
      valorant: 0,
      rocketLeague: 0
    };

    setTeamsCountByGame(localTeamsCountByGame);
  };

  const getTotalTeamResultsCount = async () => {
    const teamsCollection = (collection(firestore, 'teams').withConverter(teamConverter));

    const queryRegionConstraints = getQueryConstraintsForRegionOption(regionOption);
    const querySortingConstraints = getQueryConstraintsForSortingOption(sortingOption, null);
    const queryFilterConstraints = getQueryConstraintsForFilterOptions(filterOptions);

    const q: Query<DBTeam> = query(teamsCollection,
      ...querySortingConstraints,
      ...queryFilterConstraints,
      ...queryRegionConstraints,
      where('dissolved', '==', false));
    const localTeamsCount = (await getCountFromServer(q)).data().count;
    setTotalTeamResultsCount(localTeamsCount);
  };

  const searchTeams = async () => {
    const localSearchQuery = searchQueryRef.current;

    if (localSearchQuery !== null && localSearchQuery !== '') {
      let teams: DBTeam[] = [];
      if (import.meta.env.VITE_ENV === 'production') {
        const client = algoliasearch('1EFPJPOFKM', '725ce15dcc1a7cf9b3a0beb00b120456');
        const index = client.initIndex('teams');
        const { hits } = await index.search(localSearchQuery);
        teams = hits.map((hit) => algoliaTeamConverter(hit)).filter((team) => !team.dissolved);
      } else if (import.meta.env.VITE_ENV === 'staging') {
        const client = algoliasearch('3JUVR45ABN', '0d5ba6842ac967b9bcd83eb1f908096e');
        const index = client.initIndex('teams');
        const { hits } = await index.search(localSearchQuery);
        teams = hits.map((hit) => algoliaTeamConverter(hit)).filter((team) => !team.dissolved);
      } else {
        const usersCollection = collection(firestore, "teams").withConverter(teamConverter);
        const q = query(usersCollection,
          where('teamName', '==', localSearchQuery),
          where('dissolved', '==', false),
          limit(TeamResultsPerPage));
        const querySnapshot = await getDocs(q);
        teams = querySnapshot.docs.map((doc) => doc.data());
      }

      setTeams(teams);
      setTotalTeamResultsCount(teams.length);
    }

    setSearchQueued(false);
  };

  const debouncedSearchTeams = useMemo(() => debounce(searchTeams, 500), []);

  useEffect(() => {
    const handleSearch = async () => {
      if (searchQuery !== '') {
        setSearchQueued(true);
        debouncedSearchTeams();
      } else if (initiallyLoaded) {
        await getTeams(true, TeamResultsPerPage);
        await getTotalTeamResultsCount();
      }
    };

    searchQueryRef.current = searchQuery;
    handleSearch();

  }, [searchQuery, initiallyLoaded]);

  useEffect(() => {
    getTeamsCountByGame();
    getFeaturedTeams();
  }, []);

  useEffect(() => {
    if (filterOptionsLoaded) {
      setSearchQuery('');
      getTeams(true, TeamResultsPerPage);
      getTotalTeamResultsCount();
      saveFiltersToLocalStorage();
    }
  }, [regionOption, sortingOption, gameOption, filterOptions, filterOptionsLoaded]);

  const loadMoreTeams = (amountNeeded: number) => {
    return getTeams(false, amountNeeded);
  };

  const contextvalue = {
    teams: teams,
    featuredTeams: featuredTeams,
    totalTeamResultsCount: totalTeamResultsCount,
    teamsCountByGame: teamsCountByGame,
    loadMoreTeams: loadMoreTeams,
    loadingMore: loadingMore,
    searchQuery,
    searchQueued,
    setSearchQueued,
    setSearchQuery,
    gameOption,
    setGameOption,
    regionOption,
    setRegionOption,
    sortingOption,
    setSortingOption,
    filterOptions,
    setFilterOptions,
    initiallyLoaded: initiallyLoaded
  }

  return (
    <TeamsContext.Provider value={contextvalue}>
      {children}
    </TeamsContext.Provider>
  )
}

export default TeamsProvider;
